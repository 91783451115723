export const DEFAULT_BANNER_LIST = [
  {
    id: '885936570145062912',
    name: '0712',
    banner: '',
    path: '/en/swap/xvg-usdt',
    lang: 'en_US',
    sort: 1,
    module: 1,
    platform: ',web,h5',
    activeTime: 1689153331000,
    expireTime: 1722071733000,
    createTime: 1689162500000,
    key: '/en/swap/xvg-usdt',
    url: '',
    language: 'en_US',
    publishTime: 1689153331000,
  },
  {
    id: '813092912526409728',
    name: '2888-1',
    banner: '',
    path: 'https://www.bydfi.com/en-US/account/novice-task',
    lang: 'en_US',
    sort: 1,
    module: 1,
    platform: ',web',
    activeTime: 1671786051000,
    expireTime: 1703865600000,
    createTime: 1672021569000,
    key: 'https://www.bydfi.com/en-US/account/novice-task',
    url: '',
    language: 'en_US',
    publishTime: 1671786051000,
  },
  {
    id: '885935254085713920',
    name: '0711',
    banner: '',
    path: '/en/swap/pendle-usdt',
    lang: 'en_US',
    sort: 2,
    module: 1,
    platform: ',web',
    activeTime: 1689153015000,
    expireTime: 1722009600000,
    createTime: 1689153024000,
    key: '/en/swap/pendle-usdt',
    url: '',
    language: 'en_US',
    publishTime: 1689153015000,
  },
  {
    id: '885935026091737088',
    name: '0704',
    banner: '',
    path: '/en/swap/mav-usdt',
    lang: 'en_US',
    sort: 2,
    module: 1,
    platform: ',web',
    activeTime: 1689152957000,
    expireTime: 1725033600000,
    createTime: 1689152970000,
    key: '/en/swap/mav-usdt',
    url: '',
    language: 'en_US',
    publishTime: 1689152957000,
  },
];
