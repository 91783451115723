import { Loading } from '@/components/loading';
import { postCommonOauthLoginApi } from '@/core/api';
import { LANG } from '@/core/i18n';
import { EVENT_NAME, EVENT_TRACK, SENSORS_LOGIN } from '@/core/sensorsdata';
import { Account } from '@/core/shared';
import { useThirdPartAuth } from '@/core/store';
import { message } from '@/core/utils';
import { useRouter } from './use-router';

export const useGoogleLogin = (ru?: string) => {
  const router = useRouter();
  const { onLoginSuccess } = useThirdPartAuth();

  const postOauthLogin = async (data: { type: string; idToken: string; scene: string }) => {
    try {
      Loading.start();

      const result: any = await postCommonOauthLoginApi(data);
      if (result.code === 200) {
        EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
          type: data?.type === 'google' ? 'Google' : 'Apple',
          is_register: false,
          invite_method: '无',
          precise_invite_channel: '',
          invite_code: ru || '无',
        });
        Account.setLoginStatus(true);
        onLoginSuccess();
        SENSORS_LOGIN(result.data.uid);
        await Account.refreshUserInfo();
      } else if (result.code == 5011) {
        EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
          type: data?.type === 'google' ? 'Google' : 'Apple',
          is_register: true,
          invite_method: '无',
          precise_invite_channel: '',
          invite_code: ru || '无',
        });
        // 账号不存在
        const trace: string = result.data['trace'];
        const email: string = result.data['email'];
        router.push({
          pathname: '/register/third-register',
          query: {
            trace,
            email,
            type: data.type,
            idToken: data.idToken,
          },
        });
      } else if (result.code == 5012) {
        EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
          type: data?.type === 'google' ? 'Google' : 'Apple',
          is_register: false,
          invite_method: '无',
          precise_invite_channel: '',
          invite_code: ru || '无',
        });
        // 账号已存在
        const trace: string = result.data['trace'];
        const email: string = result.data['email'];
        router.push({
          pathname: '/login/third-bind',
          query: {
            trace,
            type: data.type,
            email,
          },
        });
      } else {
        message.error(result);
      }
    } catch (e) {
      message.error(e);
    } finally {
      Loading.end();
    }
  };

  const GoogleLoginCallback = async (idToken: string) => {
    try {
      // const idToken: string = await GoogleLogin();
      console.log('idToken', idToken);
      if (!idToken) {
        return message.error(LANG('登录失败'));
      }
      await postOauthLogin({ type: 'google', idToken, scene: 'GOOGLE_LOGIN' });
    } catch (e) {
      console.error(e);
    }
  };
  return {
    GoogleLoginCallback,
    postOauthLogin,
  } as const;
};
